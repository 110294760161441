<template>
  <PaymentMethodForm></PaymentMethodForm>
</template>

<script>
import PaymentMethodForm from "./PaymentMethodForm";
export default {
  name: "Create",
  components: {PaymentMethodForm}
}
</script>

<style scoped>

</style>